import React, { useState, useEffect } from 'react';

function App() {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch('https://fzx5dyt8a3.execute-api.us-east-1.amazonaws.com/prod/quote', {
        method: 'GET',
        headers: {
          "Accept": "application/json"
        }
      })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        setContent(data.content)
      })
      .catch((err) => {
        console.log(err.message)
        setLoading(false)
      });
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <span>{loading ? "Generating..." : (<span><i>{content}</i><p className="author">&#8211; ChatGPT</p></span>)}</span>
  );
}

export default App;
